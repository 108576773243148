//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'

import IndexNew from '@/components/page/IndexNew.vue'
import IndexOld from '@/components/page/IndexOld.vue'
export default {
  name: 'Base',
  // layout: ({ store }) => {
  //   return 'partner'
  // },
  components: { IndexNew, IndexOld },
  computed: {
    ...mapGetters('landings', ['featureEnabled']),
  },
}
